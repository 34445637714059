<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
                active
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2021
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>

          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Conoce el PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                ¿Qué es el PREP?
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            ¿Qué es el PREP?
          </h2>

          <b-embed
            type="video"
            aspect="16by9"
            controls
          >
            <source
              src="https://ayuda.ine.mx/2021/informate/assets/video/Que_es_PREP.mp4"
              type="video/mp4"
            >
          </b-embed>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                PREP
              </h4>
              <p>
                Es el mecanismo de información de los resultados electorales preliminares,
                no definitivos, donde se capturan, digitalizan, publican y cotejan los
                datos asentados en las Actas de Escrutinio y Cómputo.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-1.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
          </div>

          <div class="row align-items-center mb-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-2.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Objetivo
              </h4>
              <p>
                Informar oportunamente los resultados bajo los principios de seguridad,
                transparencia, confiabilidad, credibilidad e integridad.
              </p>
            </div>
          </div>

          <h4 class="color-institucional">
            Elementos que fortalecen al PREP
          </h4>
          <p>
            Cuenta con dos mecanismos de acompañamiento que lo transparentan,
            fortalecen y nutren de confianza su funcionamiento.
          </p>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Comité Técnico Asesor del PREP
              </h4>

              <ul>
                <li>
                  Brinda asesoría técnica en materia del PREP.
                </li>
                <li>
                  Está integrado por seis personas expertas en Estadística
                  y/o Ciencia de datos, Tecnologías de la Información y
                  Comunicaciones, Investigación de Operaciones o Ciencia
                  Política, con conocimientos en materia electoral.
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-3.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
          </div>

          <div class="row align-items-center mb-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-4.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Instituto Técnologico Nacional de México Campus La Paz
              </h4>

              <ul>
                <li>
                  Audita el sistema informático e infraestructura tecnológica del PREP.
                </li>
                <li>
                  Evalúa la integridad en el procesamiento de la
                  información y generación de resultados.
                </li>
                <li>
                  Analiza las vulnerabilidades del sistema y tecnología.
                </li>
              </ul>
            </div>
          </div>

          <div class="row align-items-center my-4">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                ¿Cuantó dura?
              </h4>

              <ul>
                <li>
                  Opera máximo 24 horas. Puede concluir antes, siempre y
                  cuando se logre el 100% del registro de las Actas
                  esperada o se agoten los recursos de recuperación de las mismas.
                </li>
                <li>
                  La publicación inicia a partir de las 20:00 horas,
                  Tiempo del centro, después del cierre de la votación.
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-5.svg" alt="" class="d-block img-fluid mx-auto">
            </div>
          </div>

          <div class="row seccion-color align-items-center my-3 text-center p-3">
            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-6.svg" alt="" class="img-fluid mx-auto d-block mb-2">
              <p>
                No sustituye los cómputos
              </p>
            </div>

            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-7.svg" alt="" class="img-fluid mx-auto d-block mb-2">
              <p>
                No es una encuesta de sálida
              </p>
            </div>

            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/quePREP-8.svg" alt="" class="img-fluid mx-auto d-block mb-2">
              <p>
                No publica resultados definitivos,
                solo preliminares, por lo tanto, no tiene efectos jurídicos.
              </p>
            </div>
          </div>

          <p class="text-center mb-5">
            <b>
              Referencias:
            </b>
            <br />
            · Artículo 219, párrafos 1 y 3 de la Ley General
            de Instituciones y Procedimientos Electorales. <br />
            · Reglamento de Elecciones, Artículos 340, 347 y 353.
          </p>

          <a
            href="https://ayuda.ine.mx/2021/informate/assets/docs/Prep/Conoce_el_PREP_Infografias/Que_es_el_PREP.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid {
  display: grid;
  align-items: center;
  gap: 45px;
  grid-template-columns: 50px 1fr;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}
</style>
